.form-search .radio [type=radio]+label,.form-inline .radio [type=radio]+label,.form-search .checkbox [type=checkbox]+label,.form-inline .checkbox [type=checkbox]+label{float:left;margin-left:-20px;}
.form-search .form-search .radio [type=radio]+label,.form-search .form-inline .radio [type=radio]+label,.form-search .form-search .checkbox [type=checkbox]+label,.form-search .form-inline .checkbox [type=checkbox]+label,.form-inline .form-search .radio [type=radio]+label,.form-inline .form-inline .radio [type=radio]+label,.form-inline .form-search .checkbox [type=checkbox]+label,.form-inline .form-inline .checkbox [type=checkbox]+label{margin-left:0;margin-right:3px;}
.form-search .input-append .search-query:focus,.form-search .input-prepend .search-query:focus{-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;}
.input-append input,.input-prepend input,.input-append select,.input-prepend select,.input-append .uneditable-input,.input-prepend .uneditable-input{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;}
input[type=checkbox],input[type=radio]{opacity:0;position:absolute;z-index:12;width:18px;height:18px;}
input[type=checkbox]:checked,input[type=radio]:checked,input[type=checkbox]:focus,input[type=radio]:focus{outline:none !important;}
input[type=checkbox]+.lbl,input[type=radio]+.lbl{position:relative;z-index:11;display:inline-block;margin:0;line-height:20px;min-height:14px;min-width:14px;font-weight:normal;}
input[type=checkbox]+.lbl.padding-16::before,input[type=radio]+.lbl.padding-16::before{margin-right:16px;}
input[type=checkbox]+.lbl.padding-12::before,input[type=radio]+.lbl.padding-12::before{margin-right:12px;}
input[type=checkbox]+.lbl.padding-8::before,input[type=radio]+.lbl.padding-8::before{margin-right:8px;}
input[type=checkbox]+.lbl.padding-4::before,input[type=radio]+.lbl.padding-4::before{margin-right:4px;}
input[type=checkbox]+.lbl.padding-0::before,input[type=radio]+.lbl.padding-0::before{margin-right:0px;}
input[type=checkbox]+.lbl::before,input[type=radio]+.lbl::before{font-family:fontAwesome;font-weight:normal;font-size:11px;color:#ffffff;content:"\a0";background-color:#ffffff;border:1px solid #CCC;box-shadow:0 1px 2px rgba(0, 0, 0, 0.05);border-radius:3px;display:inline-block;text-align:center;vertical-align:middle;height:16px;line-height:14px;min-width:16px;margin-right:1px;margin-top:8px;margin-left:8px;}
input[type=checkbox]:checked+.lbl::before,input[type=radio]:checked+.lbl::before{display:inline-block;content:'\f00c';font-size: 9px;background-color:#00B6F4;border-color:#00B6F4;border-radius:3px;box-shadow:0 1px 2px rgba(0, 0, 0, 0.05),inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),inset 15px 10px -12px rgba(255, 255, 255, 0.1);}
input[type=checkbox]:checked+.lbl-minus::before,input[type=radio]:checked+.lbl::before{font-family: 'Glyphicons Halflings'!important;font-weight: 500!important;font-size: 8px;display:inline-block;content:'\2212 ';background-color:#00B6F4;border-color:#00B6F4;border-radius:3px;box-shadow:0 1px 2px rgba(0, 0, 0, 0.05),inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),inset 15px 10px -12px rgba(255, 255, 255, 0.1);}
input[type=checkbox]:hover+.lbl::before,input[type=radio]:hover+.lbl::before,input[type=checkbox]+.lbl:hover::before,input[type=radio]+.lbl:hover::before{border-color:#1c7aad;}
input[type=checkbox]:active+.lbl::before,input[type=radio]:active+.lbl::before,input[type=checkbox]:checked:active+.lbl::before,input[type=radio]:checked:active+.lbl::before{box-shadow:0 1px 2px rgba(0, 0, 0, 0.05),inset 0px 1px 3px rgba(0, 0, 0, 0.1);}
input[type=checkbox]:disabled+.lbl::before,input[type=radio]:disabled+.lbl::before,input[type=checkbox][disabled]+.lbl::before,input[type=radio][disabled]+.lbl::before,input[type=checkbox].disabled+.lbl::before,input[type=radio].disabled+.lbl::before{background-color:#DDD !important;border-color:#CCC !important;box-shadow:none !important;color:#BBB;}
input[type=radio]+.lbl::before{border-radius:32px;font-family:Arial,Helvetica,sans-serif;font-size:36px;}
input[type=radio]:checked+.lbl::before{content:"\2022";}
.onoffswitch{position:relative;width:75px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;display:inline-block;vertical-align:middle;}
.onoffswitch.small-switch{zoom:0.8;}
.onoffswitch-checkbox{display:none;}
.onoffswitch-label{display:block;overflow:hidden;cursor:pointer;border:2px solid #FFFAFA;border-radius:20px;margin-bottom:0;}
.onoffswitch-inner{width:200%;margin-left:-100%;-moz-transition:margin 0.3s ease-in 0s;-webkit-transition:margin 0.3s ease-in 0s;-o-transition:margin 0.3s ease-in 0s;transition:margin 0.3s ease-in 0s;display:block;}
.onoffswitch-inner:before,.onoffswitch-inner:after{float:left;width:50%;height:25px;padding:0;line-height:25px;color:white;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box;}
.onoffswitch-inner:before{content:"ON";padding-left:11px;background-color:#2091cf;color:#ffffff;font-size:12px;}
.warning-switch .onoffswitch-inner:before{background-color:#df6e1e;}
.onoffswitch-inner:after{content:"OFF";padding-right:13px;background-color:#aaaaaa;color:#ffffff;text-align:left;text-indent:35px;}
.onoffswitch-switch{width:11px;margin:7px;background:#ffffff;border:2px solid #ffffff;border-radius:20px;position:absolute;top:0;bottom:0;right:44px;-moz-transition:all 0.3s ease-in 0s;-webkit-transition:all 0.3s ease-in 0s;-o-transition:all 0.3s ease-in 0s;transition:all 0.3s ease-in 0s;display:block;}
.switch-square .onoffswitch-label{border-radius:6px;}
.tick .onoffswitch-switch{border-radius:20px 0 0 20px;padding:0;margin:2px;width:30px;background-color:#aaaaaa;text-align:center;border:none;}
.tick .onoffswitch-switch:before,.tick .onoffswitch-switch:after{font-family:FontAwesome;font-weight:normal;font-style:normal;text-decoration:inherit;-webkit-font-smoothing:antialiased;content:"\f00d";position:absolute;left:0;right:0;color:#fff;line-height:25px;}
.tick .onoffswitch-switch:after{display:none;}
.tick .onoffswitch-inner:before{content:"YES";}
.tick .onoffswitch-inner:after{content:"NO";}
.switch-square .tickswitch-switch{border-radius:5px 0 0 5px;}
.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner{margin-left:0;}
.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch{right:0px;}
.tick .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch:before{content:"\f00c";}
.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch.tickswitch-switch{border-radius:0 20px 20px 0;background-color:#1c7aad;border-color:#1c7aad;background-position:-288px 1px;border-right-width:10px;}
.warning-switch .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch.tickswitch-switch{background-color:#df6e1e;border-color:#df6e1e;}
.switch-square .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch.tickswitch-switch{border-radius:0 5px 5px 0;}
