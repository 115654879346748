:root {
    --ativion-primary-text: #021535;
    --ativion-secondary-text: #0A295C;
    --ativion-tertiary-text: #1F417A;
    --ativion-primary-button: #93C4E9;
    --ativion-primary-button-active: #56A2DC;
    --ativion-primary-button-disabled: #AAD1EE;
    --ativion-secondary-button-hover: #F2F2F2;
    --ativion-tertiary-disabled: #BFC9D9;
    --ativion-stroke-disabled: #DFE4EC;
    --ativion-warning: #DF2040;
    --ativion-warning-dark: #bF0020;
    --ativion-info: #1F417A;
    --ativion-success: #0A8851;
    --ativion-white: #FFFFFF;
}