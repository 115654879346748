.switchery{
  background-color:#fff;
  border:1px solid #dfdfdf;
  border-radius:20px;
  cursor:pointer;
  display:inline-block;
  height:30px;
  position:relative;
  vertical-align:middle;
  width:50px;
  -webkit-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box
}

.switchery>small{
  border-radius:100%;
  box-shadow:0 1px 3px rgba(0,0,0,0.4);
  height:30px;
  position:absolute;
  top:0;
  width:30px
}
